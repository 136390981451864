import {bindable, customElement, inject} from "aurelia-framework";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {UserClient} from "../api/user-client";

@inject(UserClient)
@customElement('sio-phone-input')
export class PhoneInput {

    @bindable({defaultBindingMode: 2}) value;
    @bindable country;

    input;

    selectConfig = {
        set: 'country-cc',
        translateChoiceLabel: false,
        required: true,
        multiple: false,
        default: 'DE'
    };

    customPrefixes = ['90'];

    constructor(user) {
        this.userCountry = user.getCountry();
    }

    bind() {
        if (!this.country) {
            this.country = this.userCountry;
        }

        this.valueChanged();
    }

    valueChanged() {
        const customCode = this.customPrefixes.find(code => this.value.startsWith(`${code}-`));

        if (customCode) {
            const national = this.value.replace(`${customCode}-`, '');
            this.country = customCode;
            this.phone = national;
            return;
        }

        const parsed = parsePhoneNumberFromString(this.value || '', this.country);

        if (parsed) {
            this.country = parsed.country || (parsed.metadata?.country_calling_codes[parsed.countryCallingCode]?.[0]) || this.userCountry;
            this.phone = parsed.nationalNumber;
        } else {
            this.country = this.userCountry;
            this.phone = null;
        }
    }

    _updateValue()
    {
        if (this.phone && this.country) {
            if (this.customPrefixes.includes(this.country)) {
                this.value = `${this.country}-${this.phone}`;
            } else {
                const parsed = parsePhoneNumberFromString(this.phone, this.country);
                this.value = parsed?.formatInternational()?.replace(/\s+/g, '-');
            }
        } else if (!this.phone && !this.country) {
            this.value = null;
        }
    }

    countryChanged() {
        this._updateValue();
    }

    phoneChanged() {
        this._updateValue();
    }
}
