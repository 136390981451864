import {bindable, customElement, inject} from 'aurelia-framework';
import {ModelLabelService} from "../../../../reference/model-label-service";
import {I18N} from 'aurelia-i18n';

@customElement('order-item-title-display')
@inject(ModelLabelService, I18N)
export class TitleDisplay {
    //Cart title display used for moja atm

    @bindable item;

    constructor(modelLabelService, i18n)
    {
        this.modelLabelService = modelLabelService;
        this.i18n = i18n;
    }

    async bind()
    {
        if (this.item.product) {
            this.link = await this.modelLabelService.getReferenceLinks(this.item.product, false);
            this.dummyFlight = this.item.product?.estimatedFlightTime;
            if (this.item.product.freeParticipants) {
                this.item.product.freeParticipants = this.item.product?.freeParticipants?.join("\r")?.replaceAll("&amp;","&");
            }
        }
    }

    _ownTitleElements() {

        return [
            'tourism-insurance/insurance',
            'tourism-insurance/api',
            'tourism/journey',
            'tourism-ferry/ferry',
            'tourism-event-booking/event',
            'tourism-accommodation/room',
            'tourism-service/service',
            'tourism-flight/itinerary',
            'tourism-travel-package/travel-package',
            'tourism-transfer/transfer',
            'tourism-aer-api/flight',
            'tourism-bus-routing/route-itinerary',
            'tourism-event/event',
            'voucher/voucher-redeem',
            'tourism-dbtov/rail-and-fly',
            'tourism-dbtov/cruise',
            'mair-dumont/order',
            'sunny-car/rental-car',
        ];
    }

    // Don't like this implementation backend, but customer is now waiting for it until 3 weeks... Need to update it later
    getOneWayRentText() {
        const info = this.item?.data?.oneWayRentInfo;

        if (!info) return '';

        const hasEquivalent = !!info.equivalent;
        const translationKey = hasEquivalent
            ? 'tourism-sunny-car.offer.oneway.rent_with_equivalent_front'
            : 'tourism-sunny-car.offer.oneway.rent_without_equivalent_front';

        // If equivalent exists, we format it first using another translation key
        const equivalent = hasEquivalent
            ? this.i18n.tr('tourism-sunny-car.offer.equivalent_front', {
                convertedValue: info.equivalent?.convertedValue || '',
                currency: info.equivalent?.currency || ''
            })
            : '';

        // Final translation with dynamic parameters
        return this.i18n.tr(translationKey, {
            amount: info?.amount || '',
            currency: info?.currency || '',
            equivalent
        });
    }

    getBorderCrossingText() {
        const border = this.item?.data?.borderCrossingInfo;
        if (!border) return '';

        const hasEquivalent = !!border.equivalent;
        const hasTax = !!border.tax?.taxPercentage;

        const country = Array.isArray(border.country) ? border.country.join(', ') : border.country || '';
        const amount = border.amount ?? '';
        const currency = border.currency ?? '';

        const equivalent = hasEquivalent
            ? this.i18n.tr('tourism-sunny-car.offer.equivalent_front', {
                convertedValue: border.equivalent?.convertedValue || '',
                currency: border.equivalent?.currency || ''
            })
            : '';

        const tax = hasTax
            ? this.i18n.tr('tourism-sunny-car.offer.tax.exclusive_front', {
                taxPercentage: border.tax.taxPercentage
            })
            : '';

        const translationKey = hasEquivalent
            ? 'tourism-sunny-car.offer.border.crossing_with_equivalent_front'
            : 'tourism-sunny-car.offer.border.crossing_without_equivalent_front';

        return this.i18n.tr(translationKey, {
            country,
            amount,
            currency,
            equivalent,
            tax
        });
    }
}
